//
// Reactjs Popup
//

//wrapper for tooltip content
.tooltip-content{
    @media (max-width: 480px){
        width:100%;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
    }
}