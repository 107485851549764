//
// Form text
//

.form-password {
	position: relative;

  .form-control,
  .form-control{
    padding-right: calc(1.5em + 1.5rem);
  }

  .form-control.is-invalid,
  .form-control.is-valid{
    padding-right: calc(1.5em + 1.55rem + 1.5rem);
  }

  &__toggle{
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 5px;

    .svg-icon{
      color: var(--kt-text-muted);
      transition: color 0.2s;
    }

    &:hover .svg-icon{
      color: var(--kt-black);
    }

    .svg-icon svg {
      height: 1.5rem;
      width: 1.5rem;
    }

    .form-control.is-invalid + &,
    .form-control.is-valid + &{
      right: calc(1.5em + 1.55rem - 5px);
    }
  }
}
