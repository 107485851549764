.container {
    align-items: center;
    display: flex;
}
.pie-container {
    max-width: 200px;
    max-height: 200px;
}
.legend-container {
    height: fit-content;

    svg {
        height: fit-content !important;
    }
}