//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";

.nowrap {
    white-space: nowrap;
}
.va-middle {
    vertical-align: middle;
}